/* Button Purple */
.btn-custom-purple {
    color: #fff;
    background-color: #7060fc;
    border-color: #6959fc;
}

.btn-custom-purple:hover {
    color: #fff;
    background-color: #5f4efa;
    border-color: #2653d4;
}

.btn-custom-purple:focus, .btn-custom-purple.focus {
    color: #fff;
    background-color: #5f4efa;
    border-color: #2653d4;
    box-shadow: 0 0 0 0.2rem #cfbffe;
}

.btn-custom-purple.disabled, .btn-custom-purple:disabled {
    color: #fff !important;
    background-color: #5f4efa;
    border-color: #4e73df;
}

.btn-custom-purple:not(:disabled):not(.disabled):active, .btn-custom-purple:not(:disabled):not(.disabled).active,
.show > .btn-custom-purple.dropdown-toggle {
    color: #fff;
    background-color: #5f4efa;
    border-color: #5441ff;
}

.btn-custom-purple:not(:disabled):not(.disabled):active:focus, .btn-custom-purple:not(:disabled):not(.disabled).active:focus,
.show > .btn-custom-purple.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #b69ffa;
}

/* Button Orange */
.btn-custom-orange {
    color: #fff;
    background-color: #F28C28;
    border-color: #ff8001;
}

.btn-custom-orange:hover {
    color: #fff;
    background-color: #da7d20;
    border-color: #ff8001;
}

.btn-custom-orange:focus, .btn-custom-orange.focus {
    color: #fff;
    background-color: #bc6a19;
    border-color: #ff8001;
    box-shadow: 0 0 0 0.2rem #ffad5c;
}

.btn-custom-orange.disabled, .btn-custom-orange:disabled {
    color: #fff !important;
    background-color: #bc6a19;
    border-color: #ff8001;
}

.btn-custom-orange:not(:disabled):not(.disabled):active, .btn-custom-orange:not(:disabled):not(.disabled).active,
.show > .btn-custom-orange.dropdown-toggle {
    color: #fff;
    background-color: #bc6a19;
    border-color: #ff8001;
}

.btn-custom-orange:not(:disabled):not(.disabled):active:focus, .btn-custom-orange:not(:disabled):not(.disabled).active:focus,
.show > .btn-custom-orange.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #ffad5c;
}

/* Button teal */
.btn-custom-teal {
    color: #fff;
    background-color: #008080;
    border-color: #008080;
}

.btn-custom-teal:hover {
    color: #fff;
    background-color: #006666;
    border-color: #008080;
}

.btn-custom-teal:focus, .btn-custom-teal.focus {
    color: #fff;
    background-color: #006666;
    border-color: #008080;
    box-shadow: 0 0 0 0.2rem #66b2b2;
}

.btn-custom-teal.disabled, .btn-custom-teal:disabled {
    color: #fff !important;
    background-color: #006666;
    border-color: #008080;
}

.btn-custom-teal:not(:disabled):not(.disabled):active, .btn-custom-teal:not(:disabled):not(.disabled).active,
.show > .btn-custom-teal.dropdown-toggle {
    color: #fff;
    background-color: #006666;
    border-color: #008080;
}

.btn-custom-teal:not(:disabled):not(.disabled):active:focus, .btn-custom-teal:not(:disabled):not(.disabled).active:focus,
.show > .btn-custom-teal.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #66b2b2;
}